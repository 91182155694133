export const animatePortfolioPost = ( ) => {
    console.log( 'animatePortfolioPost' );

    const elSinglePortfolioWrapper = document.querySelector( '.single-portfolio-content-wrapper' );
    
    if( elSinglePortfolioWrapper ){

        const imgIntroTl = gsap.timeline();
        // document.querySelector( '.content-area' ).classList.add( 'p-0' );

        imgIntroTl.fromTo( document.querySelector( '.post-featured-image' ),
            {
                // opacity: 0,
                translateX: "100%",
                
            },
            {
                // opacity: 1,
                translateX: "0%",
                
            }
        )
        imgIntroTl.fromTo( document.querySelector( '.post-featured-image img' ),
            {
                // opacity: 0,
                translateX: "-100%",
                
            },
            {
                // opacity: 1,
                translateX: "0%",
                
            }
        , 0 )

        const elPostImg = document.querySelector( '.post-featured-image img' );
        const elPostImgOverlay = document.querySelector( '.post-featured-image .overlay' );
        const elMainContentArea = document.querySelector( '.main-content-area' );
        const controller = new ScrollMagic.Controller();
        const scene = new ScrollMagic.Scene({
            duration: "40%",
            tweenChanges: true,
        })
        .addTo( controller )

        if( am_custom.is_user_logged_in ){
            scene.addIndicators();
        }

        const tlMainImg = gsap.timeline();
        tlMainImg.to(
            elPostImg,
            {
                
                y: 70,
                scale: 1.3
            }
        )
        tlMainImg.to(
            elPostImgOverlay,
            {
                
                opacity: .5,
            }
        , 0 )
        // tlMainImg.from(
        //     elMainContentArea,
        //     {
                
        //         y: 60,
        //     }
        // , 0 )

        scene.setTween( tlMainImg );
        scene.duration( "100%" );
        scene.triggerElement( document.querySelector( '.site' ) );
        scene.triggerHook( 0 );
    }

}

window.animatePortfolioPost = animatePortfolioPost;

window.removeEventListener( 'barbaEnter', animatePortfolioPost );



window.addEventListener( 'DOMContentLoaded', () => animatePortfolioPost() );
// window.addEventListener( 'barbaEnter', animatePortfolioPost, { once: true } );